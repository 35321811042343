import { useState, useEffect, useCallback } from 'react'
import debounce from 'lodash.debounce'

const SearchForm = ({
    searchServiceUrl,
    searchResultPageUrl,
    localizationObject
}) => {
    const [searchText, setSearchText] = useState('')
    const [searchHits, setSearchHits] = useState([])

    const handleSearchSuggestions = useCallback(
        debounce((text) => {
            if (text) {
                search(searchServiceUrl, text, 7)
            } else {
                setSearchHits([])
            }
        }, 300),
        []
    )

    const search = async (serviceurl, searchText, count) => {
        const searchUrl = `${serviceurl}?q=${searchText}&type=all&start=0&count=${count}`
        const data = await fetch(searchUrl).then((fetchedData) =>
            fetchedData.json()
        )
        const hits = data?.hits ?? []
        setSearchHits(hits)
    }

    useEffect(() => {
        if (hedwig) {
            hedwig.HWSearch()
        }
    }, [])

    return (
        <div className='hw-search hw-search--large' data-hw-search>
            <div className='hw-search__inner'>
                <form
                    method='get'
                    action={searchResultPageUrl}
                    id='react-navbar-search-form'
                    role='search'
                    aria-label={
                        localizationObject.searchFormAriaLabel ??
                        'Search Form'
                    }
                >
                    <input
                        onChange={(e) => {
                            setSearchText(e.target.value)
                            handleSearchSuggestions(e.target.value)
                        }}
                        value={searchText}
                        placeholder={ localizationObject.searchFieldPlaceholder }
                        name='q'
                        data-hw-search-input
                        className='hw-search__input'
                        type='text'
                        aria-label={
                            localizationObject.searchFieldAriaLabel ??
                            'Search Field'
                        }
                        autocomplete='off'
                    />
                </form>
                <i className='far fa-search hw-search__icon'></i>
                <button
                    className='hw-search__button-inside'
                    data-hw-search-reset
                    onClick={() => {
                        setSearchText('')
                        setSearchHits([])
                    }}
                >
                    <i className='fas fa-times-circle'></i>
                </button>
            </div>
            <Suggestions
                searchHits={searchHits}
                localizationObject={localizationObject}
            />
        </div>
    )
}

function Suggestions({ searchHits, localizationObject }) {
    return (
        <ul className='hw-search__suggestions'>
            {searchHits.map((hit, index) => (
                <Suggestion
                    hit={hit}
                    localizationObject={localizationObject}
                    key={index}
                />
            ))}
        </ul>
    )
}

function Suggestion({ hit, localizationObject }) {
    return (
        <li className='hw-search__suggestion' data-hw-search-suggestion>
            <a
                className='hw-search__suggestion-link'
                href={hit.absoluteUrl}
                tabIndex='-1'
            >
                <p className='hw-search__suggestion-title'>
                    {hit._hotlistHeading}{' '}
                    {hit._type == 'media:document' ? (
                        <div className='hw-badge hw-badge--smaller'>
                            {localizationObject.mediaDocument}
                        </div>
                    ) : (
                        false
                    )}
                </p>
                <p className='hw-search__suggestion-extra'>
                    {localizationObject[hit._category]}
                </p>
            </a>
        </li>
    )
}

export default (props) => <SearchForm {...props} />
